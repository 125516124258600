import { useEffect, useState } from 'react';

import { debounce } from '../utils/debounce';

const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = debounce(() => {
      setScrollPosition(document.documentElement.scrollTop || document.body.scrollTop);
    }, 100);

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollPosition;
};

export default useScroll;
