import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useScroll from '../../hooks/useScroll';

const Spinner = () => {
  const [spinnerOffset, setSpinnerOffset] = useState(0);
  const scrollPosition = useScroll();

  const {
    size: { screenWidth, screenHeight },
  } = useSelector((state) => state.window);

  useEffect(() => {
    const headerDesktopHeight = document.querySelector('.header').offsetHeight;
    const diff = headerDesktopHeight - scrollPosition;
    const shift = diff < 0 ? 0 : diff < scrollPosition ? diff : headerDesktopHeight;
    setSpinnerOffset(shift / 2);
  }, [screenWidth, screenHeight, scrollPosition]);

  const styles = {
    top: `calc(50% + ${spinnerOffset}px)`,
  };

  return (
    <div style={styles} className="spinner">
      <div className="spinner-icon"></div>
    </div>
  );
};

export default Spinner;
